<template>
  <div class="buy-service-select-height">
    <div class="photo">
      <div class="overlay"></div>
      <div class="content">
        <base-icon icon="camera"
                   color="#fff"
                   width="50px"
                   height="50px"
                   class="icon-camera"></base-icon>
        <p class="label">Перетащите сюда<br/> или</p>
        <base-button>Загрузить</base-button>
      </div>
    </div>
    <div class="height" v-if="showHeight">
      <div class="label">Ваш рост в сантиметрах</div>
      <base-text-box v-model="height" class="input-height" />
      <buy-service-button class="button">Узнать цветотип</buy-service-button>
    </div>
    <buy-service-button class="button button-back" @click="$emit('back')">Назад</buy-service-button>
  </div>
</template>

<script>
  import BuyServiceButton from './BuyServiceButton.vue'

  export default {
    props: {
      showHeight: {
        required: false,
        type: Boolean,
        default: true
      }
    },

    components: {
      BuyServiceButton
    },

    data() {
      return {
        height: ''
      }
    }
  }
</script>

<style lang="scss" scoped>
  .buy-service-select-height {
    width: 600px;
    margin: 0 auto;

    .photo {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 300px;
      height: 400px;

      margin: 20px auto;

      background: url('/pictures/buy-service/photoPlaceholder.png') no-repeat;
      color: #fff;

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0,0,0,0.5);
        z-index: 1;
      }

      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        z-index: 2;

        & >.label {
          font-size: 14px;
          color: #fff;
          text-align: center;
          line-height: 23px;
        }
      }
    }

    .height {
      display: flex;
      flex-direction: column;

      .label {
        font-size: 14px;
        color: #fff;
        text-align: center;
        margin-bottom: 10px;
      }

      .input-height {
        text-align: center;
        /deep/ input {
          width: 160px;
          height: 80px;
          padding: 10px;
          font-size: 60px;
          font-weight: bold;
          color: #fff;
          text-align: center;
          background-color: transparent;
        }
      }
    }

    .button {
      width: 300px;
      margin: 10px auto;
    }

    .button-back {
      display: block;
      margin: 0 auto;
    }
  }
</style>