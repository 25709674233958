<template>
  <ul class="buy-service-stepper">
    <li class="step"
        v-for="step in steps"
        :key="step.number"
        :class="{ active: step.active }"
        @click="selectStep(step)">
      <span class="number">{{ step.number }}</span>
      <span class="label">{{ step.name }}</span>
    </li>
  </ul>
</template>

<script>
  export default {
    props: {
      steps: {
        required: true,
        type: Array
      }
    },

    methods: {
      selectStep(step) {
        this.$emit('change', step)
      }
    }
  }
</script>

<style lang="scss" scoped>


  .buy-service-stepper {
    display: flex;
    align-items: center;

    width: 600px;
    height: 60px;

    list-style-type: none;
    padding: 10px;
    margin: 0 auto;

    background-color: #fff;

    .step {
      display: flex;
      flex: 1 0;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;

      font-size: 16px;
      color: #727272;

      &.active, &:hover {
        color: $primary-color;

        .number {
          background-color: $primary-color;
          color: #fff;
        }
      }

      &.finished {
        color: #727272;
      }

      .number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 14px;
        height: 14px;

        color: #727272;
        font-size: 9px;
        font-weight: bold;
        background-color: #E3E3E3;
        border-radius: 50%;
      }

      .label {
        margin-left: 8px;
      }
    }
  }
</style>
