<template>
  <div class="buy-service-select-gender">
    <div class="title">Опередлеяем цветотип</div>
    <div class="genders">
      <div class="gender"
           @click="onGenderSelect('female')">
        <img src="/pictures/buy-service/female.svg">
        <div class="hint">Вы девушка</div>
      </div>
      <div class="gender"
           @click="onGenderSelect('male')">
        <img src="/pictures/buy-service/male.svg">
        <div class="hint">Вы мужчина</div>
      </div>
    </div>
    <buy-service-button class="button-back" @click="$emit('back')">Назад</buy-service-button>
  </div>
</template>

<script>
  import BuyServiceButton from './BuyServiceButton.vue'

  export default {
    components: {
      BuyServiceButton
    },

    methods: {
      onGenderSelect(gender) {
        this.$emit('finish', gender)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .buy-service-select-gender {
    width: 600px;
    margin: 40px auto 0;

    .title {
      font-weight: bold;
      font-size: 26px;
      color: #fff;
      letter-spacing: -0.35px;
      line-height: 38px;
      text-align: center;
      margin-bottom: 80px;
    }

    .genders {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 80px;

      .gender {
        cursor: pointer;
        text-align: center;

        img {
          height: 150px;
          margin-bottom: 20px;
        }

        .hint {
          font-size: 14px;
          color: #fff;
        }
      }
    }

    .button-back {
      display: block;
      margin: 0 auto;
    }
  }
</style>