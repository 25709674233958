<template>
  <div class="buy-service-take-photo">
    <div class="title">Цветотип и типаж</div>
    <div class="description">
      Загрузите фотографию и мы поможем узнать ваш цветотип и типаж, чтобы легко и быстро создавать образы из наиболее подходящих оттенков, которые будут подчеркивать цвет кожи, волос и глаз,  а не диссонировать с образом
    </div>
    <div class="drop-area">
      <base-icon icon="camera"
                 class="icon-camera"
                 color="#fff"
                 width="100px"
                 height="100px"></base-icon>

      <div class="label">Перетащите сюда фотографию<br/>или</div>
    </div>
    <buy-service-button class="button-select-photo"
                        @click="$emit('finish')">Выберите фото</buy-service-button>
    <buy-service-button class="button-back"
                        @click="$emit('back')">Назад</buy-service-button>
  </div>
</template>

<script>
  import BuyServiceButton from './BuyServiceButton.vue'

  export default {
    components: {
      BuyServiceButton
    }
  }
</script>

<style lang="scss">
  .buy-service-take-photo {
    width: 600px;
    margin: 40px auto 0;
    text-align: center;

    .title {
      font-weight: bold;
      font-size: 26px;
      color: #fff;
      letter-spacing: -0.35px;
      line-height: 38px;
      text-align: center;
      margin-bottom: 20px;
    }

    .description {
      width: 500px;
      margin: 0 auto 20px;
      font-size: 14px;
      color: #fff;
      letter-spacing: 0;
      text-align: center;
      line-height: 25px;
    }

    .drop-area {
      margin-bottom: 20px;

      .icon-camera {
        margin-bottom: 20px;
      }

      .label {
        font-weight: bold;
        font-size: 26px;
        color: #fff;
        letter-spacing: -0.35px;
        text-align: center;
        line-height: 39px;
      }
    }

    .button-select-photo {
      width: 300px;
      margin-bottom: 20px;
    }

    .button-back {
      display: block;
      width: 300px;
      margin: 0 auto;
    }
  }
</style>