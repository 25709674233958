<template>
  <button class="buy-service-button"
          @click="$emit('click')">
    <slot></slot>
  </button>
</template>

<script>
  export default {

  }
</script>

<style lang="scss" scoped>
  .buy-service-button {
    padding: 10px 20px;
    background-color: transparent;
    font-size: 16px;
    color: #fff;
    letter-spacing: 1px;
    text-align: center;
    border: 1px solid #fff;
    border-radius: 100px;
    cursor: pointer;
  }
</style>