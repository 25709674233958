<template>
  <div class="buy-service">
    <buy-service-stepper v-if="showStepper"
                         :steps="steps"
                         @change="onStepChange"></buy-service-stepper>

    <buy-service-intro v-if="currentScreen === 'intro'"
                       @finish="onIntroFinish" />

    <buy-service-select-gender v-if="currentScreen === 'selectGender'"
                               @back="onGenderBack"
                               @finish="onGenderFinish" />

    <buy-service-select-figure v-if="currentScreen === 'selectFigure'"
                               :gender="gender"
                               @back="onFigureBack"
                               @finish="onFigureFinish" />

    <buy-service-select-photo  v-if="currentScreen === 'selectPhoto'"
                               @back="onSelectPhotoBack"
                               @finish="onSelectPhotoFinish" />

    <buy-service-select-height v-if="currentScreen === 'selectHeight'"
                               :show-height="!!this.services.figureType"
                               @back="onSelectHeightBack" />
  </div>
</template>

<script>
  import hasBrandBackgroundMixin from '@/services/mixins/hasBrandBackgroundMixin.js'

  import BuyServiceStepper      from './BuyServiceStepper.vue'
  import BuyServiceIntro        from './BuyServiceIntro.vue'
  import BuyServiceSelectPhoto  from './BuyServiceSelectPhoto.vue'
  import BuyServiceSelectGender from './BuyServiceSelectGender.vue'
  import BuyServiceSelectFigure from './BuyServiceSelectFigure.vue'
  import BuyServiceSelectHeight from './BuyServiceSelectHeight.vue'

  const steps = [
    {
      active: true,
      number: 1,
      type: 'selectGender',
      name: 'Пол'
    },
    {
      active: false,
      number: 2,
      type: 'selectFigure',
      name: 'Фигура'
    },
    {
      active: false,
      number: 3,
      type: 'selectPhoto',
      name: 'Фото'
    },
    {
      active: false,
      number: 4,
      type: 'selectHeight',
      name: 'Рост'
    }
  ]

  export default {
    components: {
      BuyServiceStepper,
      BuyServiceIntro,
      BuyServiceSelectGender,
      BuyServiceSelectPhoto,
      BuyServiceSelectFigure,
      BuyServiceSelectHeight
    },

    mixins: [hasBrandBackgroundMixin],

    data() {
      return {
        steps,
        currentStep: steps[0],
        currentScreen: 'intro',
        services: {
          colorType:  null,
          figureType: null
        },
        gender: null,
        figure: null,
      }
    },

    methods: {
      onStepChange(step) {
        if (step.number > this.currentStep.number) {
          return false
        }

        this.currentScreen = step.type
        this.setActiveStep(step.type)
      },

      onIntroFinish(services) {
        this.services.colorType  = services.colorType
        this.services.figureType = services.figureType

        if (this.services.figureType) {
          this.currentScreen = 'selectGender'
        } else {
          this.currentScreen = 'selectPhoto'
        }
      },

      onGenderBack() {
        this.currentScreen = 'intro'
      },

      onGenderFinish(gender) {
        this.gender = gender
        this.currentScreen = 'selectFigure'

        this.setActiveStep('selectFigure')
      },

      onFigureBack() {
        this.currentScreen = 'selectGender'
        this.setActiveStep('selectGender')
      },

      onFigureFinish(figure) {
        this.figure = figure
        this.currentScreen = 'selectPhoto'
        this.setActiveStep('selectPhoto')
      },

      onSelectPhotoBack() {
        if (this.services.colorType && !this.services.figureType) {
          this.currentScreen = 'intro'
          this.setActiveStep('selectGender')
        } else {
          this.currentScreen = 'selectFigure'
          this.setACtiveStep('selectFigure')
        }
      },

      onSelectPhotoFinish() {
        this.currentScreen = 'selectHeight'
        this.setActiveStep('selectHeight')
      },

      onSelectHeightBack() {
        this.currentScreen = 'selectPhoto'
        this.setActiveStep('selectPhoto')
      },

      setActiveStep(type) {
        this.steps.forEach(step => step.active = false)
        this.currentStep = this.steps.find(step => step.type === type)
        this.currentStep.active = true
      }
    },

    computed: {
      showStepper() {
        return this.services.figureType && this.currentScreen !== 'intro'
      }
    }
  }
</script>

<style lang="scss" scoped>
  .buy-service {
    padding: 40px;
  }
</style>