<template>
  <div class="buy-service-intro">
    <div class="title">Что покупаем?</div>
    <ul class="services">
      <li class="service">
        <base-checkbox v-model="colorType">Цветотип</base-checkbox>
      </li>
      <li class="service">
        <base-checkbox v-model="figureType">Тип фигуры</base-checkbox>
      </li>
    </ul>
    <buy-service-button @click="onNextClick">Далее</buy-service-button>
  </div>
</template>

<script>
  import BuyServiceButton from './BuyServiceButton.vue'

  export default {
    components: {
      BuyServiceButton
    },

    data() {
      return {
        colorType: false,
        figureType: false
      }
    },

    methods: {
      onNextClick() {
        if (!this.colorType && !this.figureType) {
          return false
        }

        this.$emit('finish', {
          colorType: this.colorType,
          figureType: this.figureType
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .buy-service-intro {
    width: 600px;
    margin: 100px auto 0;
    color: #fff;
    text-align: center;

    .title {
      font-weight: bold;
      font-size: 26px;
      color: #fff;
      letter-spacing: -0.35px;
      line-height: 38px;
      text-align: center;
    }

    .services {
      display: flex;
      justify-content: center;
      list-style-type: none;
      margin: 0;
      padding: 0;

      .service {
        padding: 40px;

        /deep/ .title {
          color: #fff;
        }
      }
    }
  }
</style>