<template>
  <div class="buy-service-select-figure">
    <div class="title">Какая у вас фигура?</div>
    <div class="hint">Как опеределить тип?</div>
    <ul class="figures">
      <li v-for="figure in figures"
          class="figure"
          :key="figure.type"
          @click="onFigureSelect(figure)">
        <img class="figure-image"
             :src="figure.imgSrc">
        <div class="title">{{ figure.title }}</div>
        <div class="tick"></div>
      </li>
    </ul>

    <buy-service-button class="button-back" @click="$emit('back')">Назад</buy-service-button>
  </div>
</template>

<script>
  const femaleFigures = [
    {
      type: 'rectangle',
      title: 'Прямоугольник',
      imgSrc: '/pictures/buy-service/figure-types/female-rect.svg'
    },
    {
      type: 'triangle',
      title: 'Треугольник',
      imgSrc: '/pictures/buy-service/figure-types/female-triangle.svg'
    },
    {
      type: 'reversedTriangle',
      title: 'Перевернутый треугольник',
      imgSrc: '/pictures/buy-service/figure-types/female-reverse-triangle.svg'
    },
    {
      type: 'sandGlass',
      title: 'Песочные часы',
      imgSrc: '/pictures/buy-service/figure-types/female-sand-glass.svg'
    }
  ]

  const maleFigures = [
    {
      type: 'rectangle',
      title: 'Прямоугольник',
      imgSrc: '/pictures/buy-service/figure-types/male-rect.svg'
    },
    {
      type: 'trapeze',
      title: 'Трапеция',
      imgSrc: '/pictures/buy-service/figure-types/male-trapeze.svg'
    },
    {
      type: 'reversedTriangle',
      title: 'Перевернутый треугольник',
      imgSrc: '/pictures/buy-service/figure-types/male-reverse-triangle.svg'
    }
  ]

  import BuyServiceButton from './BuyServiceButton.vue'

  export default {
    props: {
      gender: {
        required: true,
        type: String
      }
    },

    components: {
      BuyServiceButton
    },

    data() {
      return {
        figures: this.gender === 'female' ? femaleFigures : maleFigures
      }
    },

    methods: {
      onFigureSelect(figure) {
        this.$emit('finish', figure.type)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .buy-service-select-figure {
    width: 800px;
    margin: 0 auto;
    text-align: center;

    & > .title {
      font-weight: bold;
      font-size: 26px;
      color: #fff;
      letter-spacing: -0.35px;
      line-height: 38px;
      text-align: center;
      margin-bottom: 20px;
    }

    .hint {
      display: inline-block;
      font-size: 14px;
      color: #fff;
      letter-spacing: 0;
      text-align: center;
      border-bottom: 1px dashed #fff;
      margin-bottom: 60px;
    }

    .figures {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      list-style-type: none;
      margin: 0 0 40px 0;
      padding: 0;

      .figure {
        width: 200px;
        text-align: center;
        cursor: pointer;

        &:hover {
          .tick {
            visibility: visible;
            background: url('/pictures/buy-service/figure-types/tick.svg') no-repeat;
          }

          .title {
            opacity: 1;
          }
        }

        .figure-image {
          height: 300px;
          margin-bottom: 20px;
        }

        .title {
          opacity: 0.7;
          font-size: 14px;
          color: #fff;
          letter-spacing: 0;
          margin-bottom: 20px;
        }

        .tick {
          display: inline-block;
          visibility: hidden;
          width: 50px;
          height: 50px;
          background: url('/pictures/buy-service/figure-types/tick.svg') no-repeat;

          &.active {
            background: url('/pictures/buy-service/figure-types/tick-active.svg') no-repeat;
          }
        }
      }
    }

    .button-back {
      display: block;
      margin: 0 auto;
    }
  }
</style>